import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  
  // Table Handlers
  const tableColumns = [
    { key: 'sourceTitle', sortable: true },
    { key: 'sourceDescription', sortable: true },
    { key: 'sourceDataType', sortable: true },
    { key: 'actions', class: 'text-right' },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const sourceDataType = ref(null)
  
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })
  
  const refetchData = () => {
    refListTable.value.refresh()
  }
  
  watch([currentPage, perPage, searchQuery, sourceDataType], () => {
    refetchData()
  })
  
  const fetchList = (ctx, callback) => {
    store
      .dispatch('sourceDataManagement/fetchSourceDatas', {
        Search: searchQuery.value,
        PageSize: perPage.value,
        PageIndex: currentPage.value,
        sortBy: sortBy.value,
        isSortDirDesc: isSortDirDesc.value,
        sourceDataType: sourceDataType.value,
      })
      .then(response => {
        const { data, count } = response.result;
        
        callback(data)
        totalItems.value = count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Source Data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveSourceType = type => {
    if (type === 0) return 'None'
    if (type === 1) return 'Map/Region'
    if (type === 2) return 'Modern Proximity'
    if (type === 3) return 'Ancient Proximity'
    if (type === 4) return 'Ancient Mixed Mode'
    if (type === 7) return 'Modern Mixed Mode'
    if (type === 5) return 'PCA/Region'
    if (type === 6) return 'Farmer & Hunter-Gatherer Ancestry'
    if (type === 8) return 'Custom Ancestry Break Down'
    if (type === 9) return 'Modern Break Down'
    return 'None'
  }

  return {
    fetchList,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    sourceDataType,

    resolveSourceType,
  
    refetchData,
  }
}
